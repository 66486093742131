.App {
  width: 90vw;
}

.sidebar {
  margin-right: 15px;
  margin-left: 15px;
  /*background: rgba(240, 240, 240, 0.35);*/
}

.add-shadow {
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.35);
}

.sticky-top {
  position: sticky;
  top: 0;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.main-content {
  padding: 20px;
}

.content-container {
  padding: 20px;
}

.container-box {
  border: 2px solid #ccc;
  borderRadius: 4px;
  margin: 10px auto;
}

.sidebar-item-container {
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: inherit;
  align-content: flex-start;
}

.feed-container {
  border: 2px solid #ccc;
  borderRadius: 40px;
  margin: 20px auto;
  padding: 5px;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
  font-family: inherit;
}

.feed-title {
    margin: 20px auto;
  padding: 5px;
    font-size: 1.2em;
    font-weight: 400;
    line-height: 1.1;
    color: inherit;
    font-family: inherit;
}

.feed-content {
  padding: 20px;
}

.view-lesson {
  border: 2px solid #ccc;
  borderRadius: 4px;
  margin: 10px auto;
    padding: 20px;
}

.left-side-menu-container {
  border: 2px solid #ccc;
  borderRadius: 4px;
  margin: 10px auto;
  padding: 20px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  justify-content: center;
  align-items: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.navbar-container {
  position: fixed;
  top: 55px;
  width: 100%;
  z-index: 100;
  opacity: 0.9;
  transition: opacity 0.3s ease;
  background: white;
}

.navbar-container.scrolled {
  opacity: 0;
}

.get-to-top {
  z-index: 999;
  opacity: 0.9;
  transition: opacity 0.3s ease;
}

.sticky-container {
  position: sticky;
  top: 0; /* Adjust the top value to match your header height */
  background: white;
  opacity: 0.9;
  z-index: 100;
}

.sticky-navbar {
  background-color: white;
}

.gray-background {
  background-color: #3d546f;
}

.light-gray-background {
  background-color: #3389ec;
}

.test-result-big {
  color: inherit;
  border: #1a1a1a solid 5px;
  font-family: inherit;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  transition: background-color 0.3s ease;
}

.test-result-bigtext {
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.1;
  color: inherit;
  font-family: inherit;
}

nav ul {
  list-style: none;
}

nav ul li {
  display: inline-block;
  padding: 10px;
}

h1 {
  top: 0;
}

.dragged-item {
  border: 2px dashed #999; /* Add a border to indicate the dragged item */
  cursor: grab; /* Change the cursor to the grab hand icon */
  opacity: 0.3; /* Reduce the opacity of the dragged item */
  transition: background-color 0.3s, opacity 0.3s; /* Add a transition effect */
}

.draggable-item {
  cursor: grab;
}

/*Article Box*/

.top-menu {
  flex: 1;
  width: 100%;
  z-index: 420;
  margin: 10px auto 20px;
}

.animated-resize-width {
  transition: width 0.3s ease;
}

.animated-resize-height {
  transition: height 0.3s ease;
}

.article_box {
  margin: 10px auto;
}

.chat-bubble {
  width: 90%;
  margin: 10px auto;
}

.chat-bubble-content {
  text-align: left;
  display: block;
  width: 100%;
  font-size: 20px;
  padding: 1px;
  font-weight: normal;
}

.chat-bubble-meta {
  width: 100%;
  padding: 10px 25px 10px 25px;
  position: relative;
}

.sidebar-profile-container {
  width: 100%;
  margin-top: 15px;
}

.article_box_header_link {
  align-content: center;
  display: block;
  width: 100%;
  background: #d9ffa8;
  color: rgba(0, 0, 0, .65);
  text-decoration: none;
  padding: 20px;
  font-size: 24px;
  font-weight: normal;
  position: relative;
}

.article_box_header_link::before {
  display: block;
  content: ".";
  font-size: 0;
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  border: 1px solid rgba(255, 255, 255, .2);
  border-bottom: 0;
}

.article_box_header_link span::before,
.article_box_header_link span::after {

}

.article_box_header_link span {
  /*Link hover effekt*/
  /*border-bottom: 2px dashed transparent;*/
  /*transition: border .5s;*/
}
.article_box_header_link:hover span {
  border-bottom-color: rgba(0, 0, 0, .25);
}

.article_box_meta {
  width: 100%;
  padding: 10px 25px;
  padding-top: 30px;
  position: relative;
}

.article_box_meta::before {
  content: ".";
  display: block;
  font-size: 0;
  border: 0 solid transparent;
  border-top-color: #d9ffa8;
  width: 0;
  height: 0;
  border-width: 15px 15px 0;
  position: absolute;
  top: 0;
}

.article_box_meta::after {
  content: ".";
  display: block;
  font-size: 0;
  border: 0 solid transparent;
  border-top-color: rgba(32, 32, 32, .25);
  width: 0;
  height: 0;
  border-width: 10px 10px 0;
  position: absolute;
  top: 0;
  margin-left: 5px;
}

.article_box_author_link {
  color: #fff;
  text-decoration: none;
  display: block;
  font-family: "Roboto Condensed";
  text-transform: uppercase;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.article_box_time {
  color: #fff;
  display: block;
  font-family: "Roboto Condensed";
  font-style: italic;
  font-size: 14px;
}

/***************************/

.pink .article_box_header_link {
  background: #faa0fa;
}

.pink .article_box_meta::before {
  border-top-color: #faa0fa;
}


.blue .article_box_header_link {
  background: #9ed5ff;
}

.blue .article_box_meta::before {
  border-top-color: #9ed5ff;
}
